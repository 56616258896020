import React, { useContext, useRef } from 'react';
import { MenuContext } from '../../context';

const Accordion = ({ additionalClass, id = '', title, children, target, isTealiumLink }) => {
  const accordionContainer = useRef(null);
  const accordionBody = useRef(null);
  const menuContext = useContext(MenuContext);

  return (
    <details
      id={id}
      className='accordion'
      inert={menuContext.isMenuOpen ? 'true' : null}

    >
      <summary className='accordion__head' {...(isTealiumLink) && { 'data-tealium': '' }}>
        <h3 data-tealium>
          {title}
          {target && <span id={target} style={{ visbility: 'hidden' }}></span>}
        </h3>
      </summary>
      <div className='accordion__container' ref={accordionContainer}>
        <div className={`${(additionalClass && additionalClass) || ''} accordion__body`} ref={accordionBody}>
          {children}
        </div>
      </div>
    </details>
  );
};

export default Accordion;